<!-- Settings -->
<template>
    <div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                 <el-row>
                                    <el-col :span="24" align="right">
                                      <el-dropdown  @command="handleActions($event)" size="medium" split-button class="action_btn">
                                        Actions
                                        <el-dropdown-menu slot="dropdown" class="action_dropdown">
                                          <el-dropdown-item :command="1" >Print</el-dropdown-item>
                                          <el-dropdown-item :command="2" >Download</el-dropdown-item>
                                          <el-dropdown-item :command="3" >Send Email</el-dropdown-item>
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                    </el-col>
                                  </el-row>
                                  <el-row>
                                        <el-col :span="12" align="left" v-if="view.website_details">
                                          <span v-loadimage="view.website_details.website_logo" ></span>                                           
                                        </el-col>
                                        <el-col :span="12" align="right">
                                             <b >
                                        <label class="mt-10">{{view.website_details.address}}, {{view.website_details.city_details.city_name}},{{view.website_details.state_details.state_name}} - {{view.website_details.post_code}}, {{view.website_details.country}}.</label><br>                                      
                                        <label>Phone: {{view.website_details.phone}}</label><br>
                                        <label>Fax: {{view.website_details.fax}}</label><br>
                                        <label>Email: {{view.website_details.email_address}}</label>
                                        </b>
                                        <br>
                                        </el-col>
                                  </el-row>
                                  <hr class="invoice-line">
                                  <el-row>                                    
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>LOW STOCK ORDER</b></h2>
                                      </el-col>                                                                            
                                  </el-row>
                                  <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>ORDER : </b></span><span><b> {{view.order_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>CUSTOMER ID : </b></span><span><b>{{view.customer.customer_id}}</b></span>
                                       </el-col>
                                  </el-row>
                                    <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO : </div>
                                          <div style="padding:3px" v-if="view.customer">{{view.customer?view.customer.first_name+' '+(view.customer.last_name==null?'':view.customer.last_name):''}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.address_line_1 || view.billing_address_order.address_line_2">{{ view.billing_address_order.address_line_2 }}{{view.billing_address_order.address_line_1 ? ', Unit No : '+view.billing_address_order.address_line_1 : ''}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.city_details">{{view.billing_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.state_details">{{view.billing_address_order.state_details.state_name+' - '+view.billing_address_order.post_code}}</div>
                                      </el-col>
                                      <el-col :span="8" align="left" :offset="8" v-if="view.shipping_address_order">
                                         <div class="billing-adr" style="padding-left:10px;">SHIP TO :</div>
                                         <div style="padding:3px" v-if="view.customer">{{view.customer?view.customer.first_name+' '+(view.customer.last_name==null?'':view.customer.last_name):''}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.address_line_1 || view.shipping_address_order.address_line_2">{{ view.shipping_address_order.address_line_2 }}{{view.shipping_address_order.address_line_1 ? ', Unit No : '+view.shipping_address_order.address_line_1:'' }}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.city_details">{{view.shipping_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.state_details">{{view.shipping_address_order.state_details.state_name+' - '+view.shipping_address_order.post_code}}</div>
                                      </el-col>
                                    </el-row>
                                      <div class="row">
                                      <div class="col-xl-12 ">
                                        <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center invoice-header" >
                                                    <th aria-colindex="1" role="cell"><div >ORDER DATE</div></th>
                                                    <th aria-colindex="2" role="cell"><div >CUSTOMER P.O#</div></th>
                                                    <th aria-colindex="2" role="cell"><div >ORDER PLACED BY</div></th>
                                                    <th aria-colindex="2" role="cell"><div >SALESPERSON</div></th>
                                                    <th aria-colindex="2" role="cell"><div >SHIP VIA</div></th>
                                                    <!-- <th aria-colindex="2" role="cell"><div >TERMS</div></th> -->
                                                    <th aria-colindex="1" role="cell"><div >DELIVERY DATE</div></th>
                                                </tr>
                                                <tr class="billing-cl3">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{format_date(view.created_date)}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{view.customer_po}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div>{{view.order_by}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{view.sale_person}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div>{{view.ship_via}}</div></td>
                                                    <!-- <td aria-colindex="1" role="cell" class="text-center"><div>{{view.term}}</div></td> -->
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{format_date(view.delivery_date)}}</div></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover" style="margin-top:20px;">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center" style="background-color:#366092; color: #ffffff">
                                                    <th aria-colindex="1" role="cell"><div >ITEM #</div></th>
                                                    <th aria-colindex="2" role="cell"><div >PRODUCT NAME</div></th>
                                                    <th aria-colindex="2" role="cell"><div >STOCK QTY</div></th>
                                                    <th aria-colindex="2" role="cell"><div >ORDER QTY</div></th>                                                    
                                                    
                                                </tr>
                                                <tr class="billing-cl3" v-for="item in viewitem" :key="item.id">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{item.product.sku}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{item.product.name}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div>{{item.stock_quantity}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div>{{item.order_quantity}}</div></td>
                                                </tr>
                                              
                                            </tbody>
                                        </table>                                       
                                       </div>
                                      </div>                                
                                       <el-row>                                      
                                      <el-col :span="24" align="left">
                                      <div style="text-align: center;"><b>If you have any questions about this order, please contact</b></div>
                                      <div style="text-align: center; font-size:15px;"><b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b></div>
                                      </el-col>
                                       </el-row>

                                        <div role="tablist" class="navi navi-bold navi-hover navi-active navi-link-rounded mt-5">
                                        <div v-if="view.order_verified_by" class="navi-item mb-2" style="width:100%"> 
                                          <a   v-on:click="activetab=1" v-bind:class="[ activetab === 1 ? 'active' : '' ]" class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block " style="cursor: pointer; background-color:#f6f1f1d6">ORDER VERIFIED DETAILS</a> 
                                          <div  class="card card-custom" style="height:100%" v-if="activetab === 1">
                                              <div class="view-header">
                                                <div class="row w-100">
                                                    <div class="col-md-12">
                                                       <div class="flex-grow-1">
                                                       <div class="d-flex justify-content-between flex-wrap mt-1">
                                                          <div class="d-flex mr-3">
                                                              <span class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3">Order Verified By : {{view.order_verified_by}}</span>
                                                              <span v-if="view.order_verified_by"><i class="flaticon2-correct text-success font-size-h5"></i></span>
                                                          </div>                                                                
                                                      </div>
                                                       <div class="d-flex justify-content-between flex-wrap mt-3">
                                                          <div class="d-flex mr-3">
                                                              <span class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3">Delivery Verified By : {{view.delivery_verified_by}}</span>
                                                              <span v-if="view.delivery_verified_by"><i class="flaticon2-correct text-success font-size-h5"></i></span>
                                                          </div>                                                                
                                                      </div>
                                                       <div class="d-flex justify-content-between flex-wrap mt-3">
                                                          <div class="d-flex mr-3">
                                                              <span class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3">Driver Name : {{view.driver_name}}</span>
                                                              <span v-if="view.driver_name"><i class="flaticon2-correct text-success font-size-h5"></i></span>
                                                          </div>                                                                
                                                      </div>
                                                       </div>                                                     
                                                    </div>
                                                </div>
                                              </div>
                                          </div>                                          
                                          <a v-if="view.delivery_order.delivery_document" v-on:click="activetab=2" v-bind:class="[ activetab === 2 ? 'active' : '' ]" class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block" style="cursor: pointer; background-color:#f6f1f1d6"> Delivery Documents </a> 
                                         <div  class="card card-custom" style="height:100%" v-if="activetab === 2">
                                        <div class="col-xl-12">
                                            <span class="flex-shrink-0 mr-7 mt-lg-0 mt-3"  v-for="(input,k) in view.delivery_order.delivery_document" :key="k">
                                              
                                              <img class="" :src="img_url+'/'+input.file_name" style="width:285px;height:160px;">        
                                             		
                                            </span> 
                                        </div>              
                                        </div>
                                      </div>                                     
                                        </div>
                               </div>
                            
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getLowStockOrder, downloadPdf} from "@/api/order";
import SendEmail from '@/components/SendEmail'
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      activetab: 1,
      loading: false,     
      img_url : 'https://stagingurl.tk/ctshub_api/public/images/delivery/',
      view:[],

      viewitem:[],
      view_type: 'lowstockinvoice'
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Order Management", route: "/order/list" },
      { title: "Low Stock Order" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  methods: {
    fetchData() {
      this.loading = true
      getLowStockOrder(this.$route.params.order_id).then(response => {       
        this.view = response.data.data.order 
        this.viewitem = response.data.data.low_stock_items 
        console.log(this.viewitem);     
        this.loading = false
      });
    },
    printWindow: function () {		
	     window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }else if (evt === 2){
        this.loading=true;
        downloadPdf(this.$route.params.order_id, this.view_type).then(response => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
      }else if (evt === 3){
        let customer = this.view.customer;
        let order_id = this.view.id;
        let type = this.view_type;

        this.$modal.show(SendEmail, {customer, type, order_id}, 
          { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
      }
    } 
  }
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 125px;margin-top:30px;margin-left: 10px;
    }
    
    @media print {
       
        .print-section, .download-btn, .subheader, .action_btn, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 
    

}
 
</style>